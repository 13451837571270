import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import FooterAwards from './FooterAwards'
import FooterContact from './FooterContact'
import FooterNavigation from './FooterNavigation'
import FooterSubscribe from './FooterSubscribe'
import FooterLegals from './FooterLegals'

const Footer = ({ hasAwards }) => {
  const data = useStaticQuery(graphql`
    {
      prismicGlobals {
        data {
          address
          phone_number
          email
          facebook
          instagram
        }
      }
    }
  `)

  const item = data.prismicGlobals.data

  return (
    <footer>
      {hasAwards && <FooterAwards />}
      <div className="container">
        <div className="flex flex-wrap -mx-8">
          <div className="w-full md:w-1/2 lg:w-2/3 px-8 bg-flush-left bg-sand">
            <div className="text-xs md:text-lg flex flex-wrap relative py-10 xl:py-20 -ml-8 z-20">
              <div className="w-full xl:w-2/5 pl-8 mb-8 xl:mb-0">
                <FooterContact />
              </div>
              <div className="w-full xl:w-2/5 px-8 pb-5">
                <FooterNavigation />
              </div>
              <div className="w-full xl:w-1/5 px-8">
                {item.facebook && item.instagram && (
                  <div className="flex -ml-5 md:-ml-7">
                    {item.facebook && (
                      <div className="pl-5 md:pl-7">
                        <a
                          aria-label="Follow us on Facebook"
                          className="block w-10 h-10 md:w-15 md:h-15 text-blue hover:text-blue-alt transition-color"
                          href={item.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {FacebookIcon()}
                        </a>
                      </div>
                    )}
                    {item.instagram && (
                      <div className="pl-5 md:pl-7">
                        <a
                          aria-label="Follow us on Instagram"
                          className="block w-10 h-10 md:w-15 md:h-15 text-blue hover:text-blue-alt transition-color"
                          href={item.instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {InstagramIcon()}
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 px-8 bg-flush-right bg-sand-dark">
            <div className="flex justify-end relative z-20">
              <div className="w-full md:max-w-sm py-10 xl:py-15">
                <FooterSubscribe />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterLegals />
    </footer>
  )
}

export default Footer

const InstagramIcon = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 60 60">
      <path d="M35.74,30A5.74,5.74,0,1,1,30,24.26,5.74,5.74,0,0,1,35.74,30Zm7.69-10.16a5.72,5.72,0,0,0-3.27-3.27A9.56,9.56,0,0,0,37,16c-1.82-.08-2.36-.1-7-.1s-5.14,0-7,.1a9.56,9.56,0,0,0-3.2.59,5.35,5.35,0,0,0-2,1.29,5.43,5.43,0,0,0-1.29,2A9.31,9.31,0,0,0,16,23c-.09,1.82-.1,2.36-.1,7s0,5.14.1,7a9.31,9.31,0,0,0,.59,3.2,5.72,5.72,0,0,0,3.27,3.27A9.31,9.31,0,0,0,23,44c1.82.09,2.36.1,7,.1s5.14,0,7-.1a9.31,9.31,0,0,0,3.2-.59,5.72,5.72,0,0,0,3.27-3.27A9.56,9.56,0,0,0,44,37c.09-1.82.1-2.36.1-7s0-5.14-.1-7A9.31,9.31,0,0,0,43.43,19.84ZM30,38.85A8.85,8.85,0,1,1,38.85,30,8.85,8.85,0,0,1,30,38.85Zm9.2-16a2.07,2.07,0,1,1,2.06-2.07A2.06,2.06,0,0,1,39.2,22.87ZM30,0A30,30,0,1,0,60,30,30,30,0,0,0,30,0ZM47.12,37.1a12.37,12.37,0,0,1-.8,4.18,8.75,8.75,0,0,1-5,5,12.37,12.37,0,0,1-4.18.8c-1.83.09-2.42.11-7.1.11s-5.26,0-7.1-.11a12.37,12.37,0,0,1-4.18-.8,8.85,8.85,0,0,1-5-5,12.37,12.37,0,0,1-.8-4.18c-.09-1.83-.11-2.42-.11-7.1s0-5.27.11-7.1a12.37,12.37,0,0,1,.8-4.18,8.85,8.85,0,0,1,5-5,12.37,12.37,0,0,1,4.18-.8c1.83-.09,2.42-.11,7.1-.11s5.27,0,7.1.11a12.37,12.37,0,0,1,4.18.8,8.77,8.77,0,0,1,5,5,12.37,12.37,0,0,1,.8,4.18c.09,1.83.11,2.42.11,7.1S47.21,35.27,47.12,37.1Z" />
    </svg>
  )
}

const FacebookIcon = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 60 60">
      <path d="M34.38,30h-3.2V42H26.39V30H24V25.86h2.39V23.37C26.39,20,27.75,18,31.57,18h3.21v4.14h-2c-1.51,0-1.6.57-1.6,1.64v2.08H34.8L34.38,30ZM30,0A30,30,0,1,0,60,30,30,30,0,0,0,30,0Z" />
    </svg>
  )
}
